import { BrainStats } from "../../App";
import "./StatsList.scss";
import { StatsListItem } from "./StatsListItem";

type Props = { stats?: BrainStats; hackyKey: string };
type Test = keyof BrainStats;

export const StatsList = ({ stats, hackyKey }: Props) => {
  if (!stats) return <></>;
  const items = Object.keys(stats) as Array<Test>;
  return (
    <ul className="stats-list">
      {items.map((k: Test, index) => {
        return (
          <StatsListItem key={`${index}`} value={`${stats[k]}`} label={k} />
        );
      })}
    </ul>
  );
};
