import { Suspense, useRef } from "react";

import { Canvas, useFrame } from "@react-three/fiber";
import {
  DirectionalLight,
  DirectionalLightHelper,
  Group,
  SpotLight,
  Vector3,
} from "three";

import {
  Environment,
  PerspectiveCamera,
  useHelper,
  Stats,
} from "@react-three/drei";
import { LineUp } from "../LineUp/LineUp";
import { CustomControls } from "../CustomControls/CustomControls";
import { useAppContext } from "../../context/AppContext";

const startPosCamera = new Vector3(0, 5.151436771522862, 63.77854941000632);

type WorldProps = {};
const World = ({}: WorldProps) => {
  const { currentScreen, setCurrentScreen, resetCamera, setResetCamera } =
    useAppContext();
  const routeGroups = useRef<Group>(null);
  // const [resetCam, setResetCam] = useState<boolean>(false);
  const directionalLightRef = useRef<DirectionalLight>(null!);
  useHelper(directionalLightRef, DirectionalLightHelper, 1, "red");

  const spotLightRef = useRef<SpotLight>(null!);
  // useHelper(spotLightRef, SpotLightHelper, "blue");

  useFrame(({ camera, clock }) => {
    if (!routeGroups.current) return;
    const scale = Math.min(1, window.innerWidth / window.innerHeight);
    routeGroups.current.scale.set(scale, scale, scale);
  });
  const onResetCamera = () => {
    setResetCamera(true);
  };
  const onResetCameraDone = () => {
    setResetCamera(false);
  };

  return (
    <>
      <Environment preset="city" />
      <ambientLight intensity={0.1} />

      <spotLight
        castShadow
        receiveShadow
        intensity={0.1}
        ref={spotLightRef}
        position={[0, 100, 0]}
      />
      <CustomControls
        resetPosition={resetCamera}
        onResetDone={onResetCameraDone}
      />
      <PerspectiveCamera makeDefault position={startPosCamera} />

      <group receiveShadow ref={routeGroups}>
        {/* HOME PAGE */}
        <group position={[0, -15, 0]}>
          <LineUp />
        </group>
      </group>
    </>
  );
};
type ModelProps = {};

export const ThreeD = () => {
  return (
    // <Canvas camera={{ fov: 50 }} dpr={[1, 2]}>
    <>
      <Canvas shadows dpr={[1, 2]}>
        <Suspense fallback={null}>
          <World />
        </Suspense>
        <Stats />
      </Canvas>
    </>
  );
};
