import React, { Suspense, useEffect, useMemo, useState } from "react";
import { Group, MathUtils, Object3D, Vector3 } from "three";
import { clone } from "three/examples/jsm/utils/SkeletonUtils";

import { Html, useGLTF } from "@react-three/drei";
import { useFrame, useGraph } from "@react-three/fiber";
import { AllStarModel } from "./AllStarModel";
import { Sticky } from "../Sticky/Sticky";
import { CharacterConfig } from "../../App";

export type Animations = keyof Pick<
  CharacterConfig,
  "selected" | "idle" | "notSelected"
>;

type ModelProps = {
  config: CharacterConfig;
  onButtonClick: () => void;
  position: Vector3;
  state: Animations;
};

export const AllStar = ({
  config,
  state,
  onButtonClick,
  position,
}: ModelProps) => {
  const { formPath } = config;
  const containerRef = React.useRef<Group>(null);
  const animationGroupRef = React.useRef<Group>(null);

  const animation = config[state];

  useFrame(() => {
    if (!containerRef.current) return;
    containerRef.current.position.x = position.x;
    containerRef.current.position.y = MathUtils.lerp(
      containerRef.current.position.y,
      position.y,
      0.05
    );
    containerRef.current.position.z = MathUtils.lerp(
      containerRef.current.position.z,
      position.z,
      0.05
    );
  });

  return (
    <group ref={containerRef} castShadow>
      <Suspense fallback={null}>
        <group ref={animationGroupRef}>
          {state === "selected" && (
            <Html position={[-1, 0, 0]}>
              <Sticky>
                <button
                  onClick={() => {
                    onButtonClick();
                  }}
                  className="button button--white"
                  type="button"
                >
                  <span className="MuiTypography-root MuiTypography-caption css-1fci3kk">
                    change brain
                  </span>
                  <span className="MuiTouchRipple-root css-w0pj6f"></span>
                </button>
              </Sticky>
            </Html>
          )}
          <AllStarModel animation={animation} modelPath={formPath} />
        </group>
      </Suspense>
    </group>
  );
};
