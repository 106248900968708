import { useRef, useState } from "react";
import { Group, MathUtils, TextureLoader, Vector3 } from "three";
import { AllStar, Animations } from "../AllStar/AllStar";
import { useFrame, useLoader } from "@react-three/fiber";
import { PLAYER_KEYS } from "../../App";
import { BrainHolder } from "../BrainHolder/BrainHolder";
import { Rig } from "../Rig/Rig";
import { useAppContext } from "../../context/AppContext";
import { Circle, Html } from "@react-three/drei";

const grassPath = "/Pitch-Faded.png";

type Props = {};

type HomeScreen = { screen: "players" };
type PlayerDetailsScreen = { playerId: string; screen: "playerDetails" };
type BrainSelectionScreen = { playerId: string; screen: "brainSelection" };

export type State = HomeScreen | PlayerDetailsScreen | BrainSelectionScreen;

export const LineUp = () => {
  const { currentScreen, setCurrentScreen, setResetCamera,charactersByKey,setCharactersByKey } = useAppContext();
  //  useState<State>({
  //   screen: "players",
  // });

  const pitchRef = useRef<Group>(null);
  const titleRef = useRef<Group>(null);
  const offset = -10;
  const grassTexture = useLoader(TextureLoader, grassPath);

  useFrame(() => {
    if (!pitchRef.current || !titleRef.current) return;
    pitchRef.current.position.y = MathUtils.lerp(
      pitchRef.current.position.y,
      currentScreen.screen === "players"
        ? 0
        : currentScreen.screen === "brainSelection"
        ? offset
        : 0,
      0.05
    );
    titleRef.current.position.y = MathUtils.lerp(
      titleRef.current.position.y,
      currentScreen.screen === "players"
        ? 0
        : currentScreen.screen === "brainSelection"
        ? 85
        : 85,
      0.05
    );
  });

  const brain =
    currentScreen.screen === "playerDetails" ||
    currentScreen.screen === "brainSelection"
      ? charactersByKey[currentScreen.playerId].brain
      : undefined;
  return (
    <>
      {/* No Brainers */}
      <group position={[0, 0, -1]}>
        <BrainHolder brain={brain} state={currentScreen} />
      </group>
      {/* Players */}
      <Rig>
        <group ref={titleRef}>
          <Html position={[0, 13, -10]} zIndexRange={[-5, -1]}>
            <div className="ui-get-ready">
              <h1 className="cy-select-title ui-match-title">Lil' Puckers</h1>
            </div>
          </Html>
        </group>
        <group scale={5} position={[0, 0, 0]}>
          {PLAYER_KEYS.map((key, index) => {
            let animation: Animations = "idle";
            let yPos = 0;
            let zPos = 0;
            const playerIsSelected =
              currentScreen.screen !== "players" &&
              currentScreen.playerId === key;

            if (currentScreen.screen === "players") {
              animation = "notSelected";
              yPos = 0;
              zPos = 0;
            }
            if (currentScreen.screen === "playerDetails") {
              animation = playerIsSelected ? "selected" : "idle";
              yPos = playerIsSelected ? 0 : 0;
              zPos = playerIsSelected ? 2 : -2;
            }
            if (currentScreen.screen === "brainSelection") {
              animation = playerIsSelected ? "notSelected" : "idle";
              yPos = playerIsSelected ? offset : offset;
              zPos = playerIsSelected ? 2 : -2;
            }
            // TODO: set the position of the all-star through a ref and not through it's prop
            return (
              <group
                key={key}
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    currentScreen.screen === "players" ||
                    (currentScreen.screen === "playerDetails" &&
                      currentScreen.playerId !== key)
                  ) {
                    setResetCamera(true);
                  }
                  setCurrentScreen({ screen: "playerDetails", playerId: key });
                }}
              >
                <AllStar
                  position={new Vector3(-4.5 + index * 3, yPos, zPos)}
                  config={charactersByKey[key]}
                  onButtonClick={() => {
                    setCurrentScreen({
                      screen: "brainSelection",
                      playerId: key,
                    });
                  }}
                  state={animation}
                />
              </group>
            );
          })}

          <group ref={pitchRef}>
            <Circle
              position={[0, -0.1, 0]}
              args={[8, 60]}
              rotation={[-Math.PI / 2, 0, 0]}
            >
              <meshStandardMaterial
                attach="material"
                map={grassTexture}
                transparent={true}
              />
            </Circle>
            <Circle
              receiveShadow
              args={[20, 60]}
              rotation={[-Math.PI / 2, 0, 0]}
            >
              <shadowMaterial attach="material" opacity={0.1} color="black" />
            </Circle>
          </group>
        </group>
      </Rig>
    </>
  );
};
