import { applyProps } from "@react-three/fiber";
import { Instance } from "@react-three/fiber/dist/declarations/src/core/renderer";
import { MeshPhysicalMaterial, Object3D } from "three";

export const processModel = (scene: Object3D) => {
  scene.traverse((obj) => {
    const object = obj as unknown as Instance;
    if (object.isMesh) {
      if (["OrbOuter", "axisMk2"].includes(object.name)) {
        const mat = new MeshPhysicalMaterial({
          color: 0xffffff,
          roughness: 0.01,
          metalness: 0.9,
          reflectivity: 0.2,
          opacity: 0.2,
          transparent: true,
        });

        applyProps(object, {
          material: mat,
        });
      } else if (object.name === "BrainCore") {
        const material = new MeshPhysicalMaterial({
          vertexColors: true,
          specularIntensity: 0.27,
          roughness: 0.55,
          reflectivity: 0.955,
        });
        applyProps(object, {
          material: material,
        });
        // applyProps(mesh, {
        //   "material-envMapIntensity": 0.2,
        //   "material-color": 0xff0000,
        // });
      } else if (object.name === "Brain") {
        applyProps(object, {
          // castShadow: true,
          // receiveShadow: true,
          "material-roughness": 1,
          "material-metalness": 0.01,
          "material-flatShading": false,
        });
      }
    } else if (object.isObject3D) {
      const disable = [
        // "",
        "Spot000",
        "Spot001",
        "Spot002",
        "Spot003",
        "Spot004",
        "Spot005",
      ];
      if (disable.includes(object.name)) {
        applyProps(object, {
          visible: false,
        });
      }
    }
  });
};
