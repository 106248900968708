import React, {
  Suspense,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import {
  Group,
  MathUtils,
  Mesh,
  MeshPhysicalMaterial,
  MeshStandardMaterial,
  Object3D,
  Raycaster,
  Vector2,
  Vector3,
} from "three";
import { clone } from "three/examples/jsm/utils/SkeletonUtils";

import {
  Center,
  Html,
  Sphere,
  useAnimations,
  useGLTF,
  useProgress,
} from "@react-three/drei";
import { applyProps, useFrame, useGraph } from "@react-three/fiber";
import { Sticky } from "../Sticky/Sticky";
import { BrainStats, CharacterConfig } from "../../App";
import { State } from "../LineUp/LineUp";
import "./Brain.scss";
import { Instance } from "@react-three/fiber/dist/declarations/src/core/renderer";
import { processModel } from "./BrainUtils";

export type BrainProps = {
  path: string;
  onLoaded: (modelPath: string) => void;
};
type Props = BrainProps & {};

export const Brain = ({ path, onLoaded }: Props) => {
  const brainRef = React.useRef<Group>(null);
  const data = useGLTF(path);
  const { scene, materials, animations } = data;
  const { actions, mixer } = useAnimations(animations, brainRef);

  const { nodes } = useGraph(scene);

  useEffect(() => {
    Object.entries(actions).forEach(([id, a]) => {
      a?.play();
    });
  }, [actions]);

  useLayoutEffect(() => {
    processModel(nodes.Scene);
    onLoaded(path);
  }, []);
  return <primitive ref={brainRef} object={nodes.Scene} scale={12} />;
};
