import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { Group, MathUtils, Object3D, Raycaster, Vector2, Vector3 } from "three";
import { clone } from "three/examples/jsm/utils/SkeletonUtils";

import { Center, Html, Sphere, useGLTF, useProgress } from "@react-three/drei";
import { useFrame, useGraph } from "@react-three/fiber";
import { Sticky } from "../Sticky/Sticky";
import { BrainStats, CharacterConfig } from "../../App";
import { State } from "../LineUp/LineUp";
import "./BrainHolder.scss";
import { Brain } from "../Brain/Brain";
import { StatsList } from "../StatsList/StatsList";
import { mapRange } from "../../math";
import gsap from "gsap";
import { isValidDateValue } from "@testing-library/user-event/dist/utils";
import { useAppContext } from "../../context/AppContext";

type Props = {
  state: State;
  brain?: { path: string; stats: BrainStats };
};

export const BrainHolder = ({ brain, state }: Props) => {
  const containerRef = useRef<Group>(null);
  const htmlRef = useRef<Group>(null);
  const statsRef = useRef<Group>(null);
  const brainRef = useRef<Group>(null);
  const brainLoadingRef = useRef<Group>(null);
  const [isAnimatingOut, setIsAnimatingOut] = useState<boolean>(false);
  const [currentModelPath, setCurrentModelPath] = useState<string | undefined>(
    brain?.path
  );
  const { currentScreen } = useAppContext();

  const onBrainLoaded = (brainModelPath: string) => {
    if (brainLoadingRef.current) {
      gsap.to(brainLoadingRef.current.position, {
        y: 0,
        x: 0,
        duration: 0.5,
        onComplete: () => {
          setIsAnimatingOut(false);
        },
      });
    } else {
      setIsAnimatingOut(false);
    }
  };

  useEffect(() => {
    if (brain && brain.path !== currentModelPath) {
      setIsAnimatingOut(true);
      if (brainLoadingRef.current) {
        const isOnBrainScreen = currentScreen.screen === "brainSelection";
        const ypos = isOnBrainScreen ? -90 : 23;
        const xpos = isOnBrainScreen ? 0 : 0;
        gsap.to(brainLoadingRef.current.position, {
          y: ypos,
          x: xpos,
          duration: isOnBrainScreen ? 0.8 : 0.3,
          onComplete: () => {
            setCurrentModelPath(brain?.path);
          },
        });
      }
    }

    return () => {
      gsap.killTweensOf(brainLoadingRef.current);
    };
  }, [brain, brainLoadingRef, currentModelPath, currentScreen]);

  useFrame(({ clock }) => {
    if (!containerRef.current || !htmlRef.current || !statsRef.current) return;

    containerRef.current.position.y = MathUtils.lerp(
      containerRef.current.position.y,
      state.screen === "players"
        ? 100
        : state.screen === "playerDetails"
        ? 45
        : 0,
      0.05
    );
    htmlRef.current.position.y = MathUtils.lerp(
      htmlRef.current.position.y,
      state.screen === "brainSelection" ? 10 : -100,
      0.05
    );
    statsRef.current.position.y = MathUtils.lerp(
      statsRef.current.position.y,
      state.screen === "players"
        ? 100
        : state.screen === "playerDetails"
        ? -5
        : 30,
      0.05
    );

    if (!brainRef.current) return;

    const zDepth = 0; //state.screen === "brainSelection" ? 30 : 0;

    brainRef.current.position.y =
      Math.cos(clock.getElapsedTime() * 1.75) * 1.24;

    brainRef.current.position.z =
      zDepth + Math.cos(clock.getElapsedTime() * 1.75) * 1.24;

    const speed = mapRange(
      Math.cos(clock.getElapsedTime()),
      -1,
      1,
      0.001,
      0.002,
      true
    );
    brainRef.current.rotation.y += speed;
    brainRef.current.rotation.x += speed; //0.001;
    // brainRef.current.rotation.z += 0.001;
    // Math.cos(clock.getElapsedTime() * 0.75) * 0.24;
  });

  return (
    <group>
      <group ref={htmlRef}>
        {/* <Html position={[0, 10, 0]}>GEN I #9988</Html> */}
      </group>

      <group ref={containerRef} position={[0, 100, 0]}>
        <group>
          <group ref={statsRef}>
            <Html position={[0, -3, 0]}>
              <div className="stats">
                <StatsList stats={brain?.stats} hackyKey={brain?.path || "x"} />
              </div>
            </Html>
          </group>
          <group ref={brainLoadingRef}>
            <Suspense>
              <group ref={brainRef}>
                {currentModelPath && (
                  <Brain
                    // we force a re-render based on key
                    // this is needed in order to ensure the "onLoaded" callback
                    // is called
                    key={currentModelPath}
                    onLoaded={onBrainLoaded}
                    path={currentModelPath}
                  />
                )}
              </group>
            </Suspense>
          </group>
        </group>
      </group>
    </group>
  );
};
