import { useState } from "react";
import CountUp from "react-countup";
import "./StatsList.scss";
type Props = { value: string; label: string };

export const StatsListItem = ({ value, label }: Props) => {
  const [oldValue, setOldValue] = useState<string>("50");

  return (
    <li className="stats-list__item">
      <p>
        <CountUp
          end={Number(value)}
          start={Number(oldValue)}
          onEnd={() => {
            setOldValue(value);
          }}
          duration={0.4}
        />
      </p>
      <p className="stats-list__item-label">{label}</p>
    </li>
  );
};
